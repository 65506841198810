import React, { useEffect } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import API, { graphqlOperation } from "@aws-amplify/api"
import { checkProfile, fetchProfile } from "../../../state/actions/user"
import Loading from "../../common/Loading"

const ProfileRoute = ({
  activitySelectStage,
  children,
  profileCreateStatus,
  profileFetchStatus,
  profileIsComplete,
  checkProfile,
  fetchProfile,
  needColors,
  url,
}) => {
  useEffect(() => {
    if (profileFetchStatus === "ok") return

    checkProfile(API, graphqlOperation).then(exists => {
      if (exists) {
        fetchProfile(API, graphqlOperation).then(() => {
          // navigate("/myaccount/proposals/", { replace: true })
        })
        // error manage
      } else {
      }
    })
  }, []) // eslint-disable-line

  if (profileFetchStatus === "ok") {
    if (profileIsComplete !== true) {
      // we're missing something in the user profile
      // need to retake questionnaire
      navigate("/myaccount/onboarding/", { replace: true })
      return null
      // } else if (profileColor === null && needColors !== false) {
      //   // haven't picked a color yet (or just changed persona)
      //   // need to pick first
      //   navigate("/myaccount/tailoring-progress/", { replace: true })
      //   return null
    } else if (
      activitySelectStage !== 1 &&
      !url.includes("myaccount/select-activity")
    ) {
      // haven't picked an activity with the new selector
      // need to do that first
      navigate("/myaccount/select-activity/", { replace: true })
      return null
    } else {
      return children
    }
  } else if (profileCreateStatus === "not_exists") {
    navigate("/myaccount/init/", { replace: true })
    return null
  } else return <Loading />
}

ProfileRoute.defaultProps = { url: "" }

export default connect(
  ({ questionnaire, user }) => ({
    profileCreateStatus: user.profile.createStatus,
    profileFetchStatus: user.profile.fetchStatus,
    profileIsComplete: user.profile.isComplete,
    activitySelectStage: questionnaire.activitySelectStage,
  }),
  {
    checkProfile,
    fetchProfile,
  }
)(ProfileRoute)
