import { useState } from "react"
import { validateStartupName } from "../../helpers/validation"

const useStartupNameForm = (initValue, successCallback) => {
  const [inputValue, setInputValue] = useState(initValue)
  const [inputError, setInputError] = useState("")

  const handleInputChange = event => {
    event.persist()

    // make sure "minute" symbol is parsed as apostrophe
    let value = event.target.value.replace("'", "’")
    setInputValue(value)

    if (value.length > 1) validateStartupName(value, setInputError)
  }

  const handleSubmit = () => {
    if (validateStartupName(inputValue, setInputError) !== true) return
    successCallback()
  }

  return {
    inputError,
    inputValue,
    handleInputChange,
    handleSubmit,
  }
}

export default useStartupNameForm
