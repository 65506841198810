import { validatorStartupName } from "./validatorStartupName"

const readableErrorMessage = (name, validity) => {
  if (name === "tos" && validity.valueMissing === true)
    return "you need to accept our terms and conditions to purchase this logo."
  if (validity.valueMissing === true) return "this field is required"
  if (validity.typeMismatch === true) return `this is not a valid ${name}`
  return "this field is not valid"
}

export const validateInput = (inputRef, setInputErrors) => {
  let input = inputRef.current
  let name = input.name

  if (input.checkValidity() !== true) {
    let errorMessage = readableErrorMessage(name, input.validity)
    setInputErrors(errors => ({ ...errors, [name]: errorMessage }))
  } else setInputErrors(errors => ({ ...errors, [name]: "" }))

  return
}

export const validateInputs = (inputRefs, setInputErrors) => {
  let allValid = true
  let focusFirstInput = false

  inputRefs.map(inputRef => {
    let input = inputRef.current
    let name = input.name

    if (input.checkValidity() !== true) {
      allValid = false
      let errorMessage = readableErrorMessage(name, input.validity)
      setInputErrors(errors => ({ ...errors, [name]: errorMessage }))

      // give focus to first invalid input
      if (focusFirstInput !== true) {
        input.focus()
        focusFirstInput = true
      }
    } else setInputErrors(errors => ({ ...errors, [name]: "" }))

    return inputRef
  })

  return allValid
}

export const validateStartupName = (startupName, setInputError) => {
  const is_valid = validatorStartupName(startupName)
  let errorMessage = ""

  if (is_valid.canSubmit) {
    setInputError("")
    return true
  } else if (is_valid.minLengthIssue === true)
    errorMessage = "Startup name should be at least 2 characters long."
  else if (is_valid.maxLengthIssue === true)
    errorMessage =
      "We don't support startup names longer than 22 characters for now."
  else if (is_valid.wordLengthIssue === true)
    errorMessage =
      "Please add a space between words if you can. We don't support words longer than 12 characters for now."
  else if (is_valid.wrongCharacters.length > 0)
    errorMessage = `We don't support these characters for now: ${is_valid.wrongCharacters.toString()}.`

  setInputError(errorMessage)
  return false
}
