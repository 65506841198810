// extracted from monorepo
// didn't use the dependency directly to avoid exposing private functions to the public
const VALID_NAME_CHARACTERS = " ABCDEFGHIJKLMNOPQRSTUVWXYZÇŒabcdefghijklmnopqrstuvwxyzàêéëèîïöñùçœ&-+*|0123456789.:,!?’".split(
  ""
)

const checkStartupNameCharacters = value => {
  let wrongCharacters = []

  value.split("").forEach(char => {
    if (VALID_NAME_CHARACTERS.indexOf(char) === -1) wrongCharacters.push(char)
  })

  return wrongCharacters
}

const minLengthIssue = value => value.trim().length < 2

const wordLengthIssue = value => {
  let tooLong = false
  let words = value.split(" ")
  words.map(w => {
    if (w.length > 12) tooLong = true
    return null
  })
  return tooLong
}

const validatorStartupName = value => {
  if (typeof value !== "string") throw new Error("param should be a string")

  const wrongCharacters = checkStartupNameCharacters(value)
  const maxLengthIssue = value.length > 22
  const canSubmit =
    maxLengthIssue === false &&
    wrongCharacters.length === 0 &&
    minLengthIssue(value) === false &&
    wordLengthIssue(value) === false

  return {
    canSubmit,
    minLengthIssue: minLengthIssue(value),
    wordLengthIssue: wordLengthIssue(value),
    name: value,
    maxLengthIssue,
    wrongCharacters,
  }
}

export { validatorStartupName }
