import React, { useState } from "react"
import { connect } from "react-redux"
import {
  Button,
  FormInput,
  QuestionnaireActionBtns,
  Grid3TopBar,
  GridBox,
  GridContainer,
  Spinner,
  Standalone,
  Title,
} from "@thedgbrt/logology-ui-components"
import { navigate } from "gatsby"
import { Link } from "gatsby"
import API, { graphqlOperation } from "@aws-amplify/api"
import {
  setStartupName,
  updateStartupName,
} from "../../state/actions/questionnaire"
import { resetProposals } from "../../state/actions/proposals"
import { resetProposalAssocPreviews } from "../../state/actions/proposalAssocPreviews"
import { resetProposalPreviews } from "../../state/actions/proposalPreviews"
import { trackAmplitude } from "../../helpers/amplitude"
import { trackSegmentEvent } from "../../helpers/segment"
import useStartupNameForm from "../QuestionnaireApp/useStartupNameForm"

const ChangeStartupName = ({
  initValue,
  isLoggedIn,
  updateStartupName,
  resetProposals,
  resetProposalAssocPreviews,
  resetProposalPreviews,
  setStartupName,
}) => {
  let [error, setError] = useState(false)
  let [loading, setLoading] = useState(false)

  let inputRef = React.createRef()

  const linkBasePath = isLoggedIn
    ? "/myaccount/proposals"
    : "/anonaccount/proposals"

  const successCallback = async () => {
    setError(false)
    setLoading(true)

    if (isLoggedIn === true) {
      updateStartupName(API, graphqlOperation, inputValue)
        .then(res => {
          if (res === true) {
            trackSegmentEvent("track", "Change Startup Name", {
              startupName: inputValue,
            })

            trackAmplitude("Change Startup Name", {
              startupName: inputValue,
            })

            resetProposals()
            resetProposalPreviews()
            resetProposalAssocPreviews()

            navigate(linkBasePath)
          }
          return
        })
        .catch(() => {
          setError(true)
          setLoading(false)
        })
    } else {
      setStartupName(inputValue)

      trackSegmentEvent("track", "Change Startup Name", {
        startupName: inputValue,
      })

      trackAmplitude("Change Startup Name", {
        startupName: inputValue,
      })

      resetProposals()
      resetProposalPreviews()

      navigate(linkBasePath)
    }
  }

  const {
    inputError,
    inputValue,
    handleInputChange,
    handleSubmit,
  } = useStartupNameForm(initValue, successCallback)

  return (
    <>
      <Grid3TopBar>
        <Link to={linkBasePath}>← back to proposals</Link>
      </Grid3TopBar>

      <GridContainer type={4}>
        <GridBox colSpans={[[2, 4], [2, 4], [2, 6]]} type={4}>
          <Title level={2} standalone>
            Update my brand name
          </Title>
        </GridBox>
      </GridContainer>

      <GridContainer type={4}>
        <GridBox colSpans={[[2, 5], [3, 4], [3, 6]]} type={4}>
          <Standalone>
            {error ? (
              <p>
                <b>
                  There was an error updating your brand name. Please try again.
                </b>
              </p>
            ) : null}
            {loading ? (
              <Spinner />
            ) : (
              <>
                <FormInput
                  placeholder="Type in your brand name"
                  type="text"
                  name="startupName"
                  id="startup-name-input"
                  error={inputError}
                  onChange={e => handleInputChange(e, inputRef)}
                  value={inputValue}
                  ref={inputRef}
                />

                <QuestionnaireActionBtns>
                  <Button onClick={handleSubmit}>update →</Button>
                </QuestionnaireActionBtns>
              </>
            )}
          </Standalone>
        </GridBox>
      </GridContainer>
    </>
  )
}

export default connect(
  ({ questionnaire, user }) => ({
    initValue: questionnaire.startupName,
    isLoggedIn: user.isLoggedIn,
  }),
  {
    updateStartupName,
    resetProposals,
    resetProposalAssocPreviews,
    resetProposalPreviews,
    setStartupName,
  }
)(ChangeStartupName)
