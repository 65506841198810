const trackAmplitude = (event, data = {}) => {
  // only track events on live website
  if (window.location.hostname.indexOf("logology.co") > -1) {
    if (window && window.amplitude) {
      window.amplitude.getInstance().logEvent(event, data)
      if (window.mixpanel) {
        window.mixpanel.track(event, data)
      }
    }
  }
}

const trackAmplitudeIdentity = (sub, email) => {
  if (window.location.hostname.indexOf("logology.co") > -1) {
    if (window && window.amplitude) {
      const identity = new window.amplitude.Identify().set("email", email)
      window.amplitude.getInstance().identify(identity)
      if (window.mixpanel) {
        window.mixpanel.people.set({
          $email: email,
        })
        window.mixpanel.identify(sub)
      }
    }
  }
}

const trackAmplitudeRevenue = (price, quantity) => {
  if (window.location.hostname.indexOf("logology.co") > -1) {
    if (window && window.amplitude) {
      const revenue = new window.amplitude.Revenue()
        .setPrice(price)
        .setQuantity(quantity)
      window.amplitude.getInstance().logRevenueV2(revenue)
    }
  }
}

export { trackAmplitude, trackAmplitudeRevenue, trackAmplitudeIdentity }
