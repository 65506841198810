// Private routes use heavy AUTH dependencies from aws
// therefore it can be slow to load the first time.
// We use loadable components to render the page
// and then asynchronously load the components
import React from "react"
import Loadable from "@loadable/component"
import { GridBox, Spinner } from "@thedgbrt/logology-ui-components"

const PrivateRouteLoadable = Loadable(() => import("./index"), {
  fallback: (
    <GridBox colSpans={[[2, 5], [2, 7], [2, 8]]} withBgContainer>
      <Spinner standalone />
    </GridBox>
  ),
})

export default PrivateRouteLoadable
