import React from "react"
import { makeRem, Spinner } from "@thedgbrt/logology-ui-components"

const Loading = () => (
  <Spinner
    standalone
    style={{
      position: "absolute",
      margin: `${makeRem(20)} auto`,
      left: 0,
      right: 0,
    }}
  />
)

export default Loading
