import React from "react"
import PrivateRoute from "../../../../components/auth/PrivateRoute/loadable"
import ProfileRoute from "../../../../components/auth/ProfileRoute"
import ChangeStartupName from "../../../../components/MyAccount/ChangeStartupName"

const ChangeStartupNamePage = props => (
  <PrivateRoute url={props.location.href}>
    <ProfileRoute needColors={false} url={props.location.href}>
      <ChangeStartupName />
    </ProfileRoute>
  </PrivateRoute>
)

export default ChangeStartupNamePage
